import React, { Dispatch, Key, SetStateAction, useRef, useEffect, useState } from 'react';
import './WishList.scss';
import { fetchAllWishes, cancelGrant, fetchUserWish } from '../api/api';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Wish } from '../models/wish';
import WishItem from './WishItem';
import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg';
import { ReactComponent as TreeIcon } from '../assets/icons/tree-view.svg';
import { ReactComponent as ListIcon } from '../assets/icons/list-view.svg';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import HeaderLogo from './HeaderLogo';
import Ball from './TreeBall';
import Popup from './Popup';

const BALL_TOP_OFFSET = [79, 45, 63, 87, 48, 68];
// [49.33, 4.53, 34.40, 60.00, 17.73, 44.33];

function WishList({role}: {role: string}) {
	const boxRef = useRef<HTMLDivElement>(null);
	const [view, setView] = useState('wishes');
	const [data, setData] = useState<Wish[]>([]);
	const [currentWish, setCurrentWish] = useState<Wish | null>(null);
	const [page, setPage] = useState<number>(1);
	const [prevPage, setPrevPage] = useState<number>(1);
	const [totalPages, setTotalPages] = useState<number>(1);
	const [isTree, setIsTree] = useState(true);
	const [selectedWish, setSelectedWish] = useState<Wish | null>(null);

	const views = [
	{
		name: 'wishes',
		title: 'Желания'
	},
	{
		name: 'pending',
		title: 'Заявки на желания'
	},
	// {
	// 	name: 'volunteers',
	// 	title: 'Заявки на исполнение'
	// }
	];

	const fetchData = async () => {
		try {
			const { data } = await fetchAllWishes();
			setData(data.wishes);
			setTotalPages(data.approvedWishPages || 1);
			return data;
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const getCurrentWish = async () => {
		try {
			const { data } = await fetchUserWish();
			setCurrentWish(data.wish)
			return data.wish;
		} catch (error) {
			console.error('Error:', error);
		}
	}

	const changePage = async (newPage: number) => {
		if (newPage > 0 && newPage <= totalPages) {
			setPrevPage(page);
			setPage(+newPage);
		}  
	};

	const cancelWishGrant = async (wishId: string) => {
		if (wishId) {
			await cancelGrant(wishId);
			fetchData();
		}  
	};

	const updateView = (newView: string) => {
		setView(newView);
	};

	const onBallSelect = (wish: Wish) => {
		setSelectedWish(wish);
	};

	const NewYearTree = () => (
		<div
			className="wishList-balls"
		>
			<div
				style={{
					transform: `translateX(${- (page - 1)* 100}%)`
				}}
				className="wishList-ballsFrame"
				ref={boxRef}
			>
				{data.filter(wish => wish.approved === true).map((wish, index) => {
					const approvedWishes = data.filter((w) => w.volunteer?.approved);
					const falsyWishes = data.filter((w) => !w.volunteer?.approved);
					const approvedIndex = approvedWishes.findIndex((w) => w.id === wish.id);
					const falsyIndex = falsyWishes.findIndex((w) => w.id === wish.id);

					const ballIndex = (approvedIndex === -1) ? falsyIndex : approvedIndex;
					return (
						<div
							className="wishList-ballContainer"
							style={{
								left: `calc(5px + ${index * 16.666}%)`,
								height: `calc(${BALL_TOP_OFFSET[index % BALL_TOP_OFFSET.length]}%)`,
							}}
						>
							{/* <Link to={`/wish/${wish.id}?token=${localStorage.getItem('token')}`}> */}
								<img
									className="wishList-ball"
									key={index}
									src={Ball(wish?.volunteer?.approved, ballIndex)}
									alt='Christmas Ball'
									onClick={() => onBallSelect(wish)}
								/>
							{/* </Link> */}
						</div>
					);
				})}
			</div>
		</div>
	);

	const WishStatus = ({wish}: {wish: Wish}) => {
		return (wish?.volunteer?.canGrant) ? (
			['admin', 'volunteer'].includes(role) ? (
				<Link className='wishList-button' to={`/wish/${wish.id}?token=${localStorage.getItem('token')}`}><button>Хочу исполнить желание!</button></Link>
			) : null
		) : (
			wish?.volunteer?.approved ? (
					(role === 'admin') ? (
							<button className='wishList-button' onClick={() => cancelWishGrant(wish.id, )}>Отменить</button>
						) : null


			) : null
			//: 'Исполнитель верифицируется'}</div>
		)
	};

	const NewYearList = () => (
		<SimpleBar className="wishList-container">
			{data.filter(wish => (view === 'pending') ? true : (wish.approved === true)).map((wish: Wish) => (
				<div
					className="wishList-item"
					key={wish.id as Key | null | undefined}
				>
					{wish ? <WishItem wish={wish} strip_name={true}></WishItem> : null}
					<br/>
					{(view === 'pending') ? (
						<Link to={`/validate-wish/${wish.id}?token=${localStorage.getItem('token')}`}>
							<button>Просмотр</button>
						</Link>
					) : ((view === 'volunteers') ? (
						<Link to={`/validate-volunteer/${wish.id}?token=${localStorage.getItem('token')}`}>
							<button>Просмотр</button>
						</Link>
					) : <WishStatus wish={wish}/>)}
				</div>
			))}
		</SimpleBar>
	)

	useEffect(() => {
		getCurrentWish();
		fetchData();
		setPage(1);
	}, []);

	useEffect(() => {
		console.log('run animation');
		const box = boxRef.current;

		if (box) {
			// Define the animation keyframes
			const keyframes = [
				{ transform: `translateX(${- (prevPage - 1)* 100}%)` },
				{ transform: `translateX(${- (page - 1)* 100}%)` }
			];

			// Define animation options (duration, delay, easing, etc.)
			const options = {
				duration: 1000,
				iterations: 1
			};

			// Create the animation
			const animation = box.animate(keyframes, options);

			// // Pause the animation after 2 seconds
			// setTimeout(() => {
			// 	animation.pause();
			// }, 999);

			// Clean up animation on component unmount
			return () => {
				animation.cancel();
			};
		}
	}, [page]);

	return (
		<>
			<div className="wishList">
				<div className="wishList-header">
					<HeaderLogo/>
					<div className="wishList-select">
						{(role === 'admin') ? (
							<>
								<select onChange={(evt) => updateView(evt.target.value)}>
									{views.map((view) => {
										return <option key={view.name} value={view.name}>{view.title}</option>;
									})}
								</select>
							</>
						) : null}
						{
							(view === 'wishes') ? (
								<span className='wishList-viewContainer'>
									<span onClick={() => setIsTree(!isTree)} className='wishList-view'>{isTree ? 'В виде списка' : 'В виде шариков'}</span>
									<span onClick={() => setIsTree(!isTree)} className='wishList-view--mobile'>
										{isTree ? <ListIcon/> : <TreeIcon/>}
									</span>
									{
										(['wishmaker'].includes(role)) ? (
											<Link className="wishList-rulesLink" to={`/rules-wishmaker/?token=${localStorage.getItem('token')}`}>
												Правила участия
											</Link>
										) : (
											<Link className="wishList-rulesLink" to={`/rules/?token=${localStorage.getItem('token')}`}>
												Правила участия
											</Link>
										)
									}
								</span>
							) : null
						}
					</div>
				</div>
				{
					(isTree && view === 'wishes') ? <NewYearTree /> : <NewYearList />
				}

				{
					((currentWish) ? (
						<div className="preFooterNav">
							<div className="preFooterNav-nav">
								<span style={{color: 'gold'}}><b>Заявка на желание отправлена</b></span>
								<br/>
								<span style={{color: 'orange'}}><b>Статус заявки: </b></span>
								{(!currentWish?.checked) ?
									<span style={{color: 'orange'}}>В ожидании одобрения заявки</span> :
									((currentWish?.approved) ? <span style={{color: 'green'}}>Заявка одобрена</span> :
											(
												<span
													style={{color: 'red'}}>В заявке отказано по причине: {currentWish?.comments?.slice(-1)[0]} </span>
											)
									)
								}
								<br/>
							</div>
						</div>

					) : null)
				}

				<div className='footerNav'>
					<div className='footerNav-nav'>
						<ArrowIcon className={`footerNav-icon footerNav-icon--left ${page === 1 ? 'footerNav-icon--disabled' : ''}`} onClick={() => changePage(page - 1)} />
						{
							(['wishmaker'].includes(role) && !currentWish) ? (
								<Link to={`/new-wish/?token=${localStorage.getItem('token')}`}>
									<button className='footerNav-button'>Хочу создать заявку!</button></Link>
							) : null
						}
						{
							(currentWish?.checked && !currentWish?.approved) ?
							<Link to={`/new-wish/?token=${localStorage.getItem('token')}`}>
								<button className='footerNav-button'>Хочу обновить заявку!</button>
							</Link>
							: null
						}

						{/* <Link to={`/?token=${localStorage.getItem('token')}`}>На главную</Link> <br/> */}
						<ArrowIcon className={`footerNav-icon footerNav-icon--right ${page < totalPages ? '' : 'footerNav-icon--disabled'}`} onClick={() => changePage(page + 1)} />
					</div>
					{
						(['wishmaker'].includes(role)) ? (
							<Link className="footerNav-rulesLink" to={`/rules-wishmaker/?token=${localStorage.getItem('token')}`}>
								Правила участия
							</Link>
						) : (
							<Link className="footerNav-rulesLink" to={`/rules/?token=${localStorage.getItem('token')}`}>
								Правила участия
							</Link>
						)

					}

				</div>
				{
					(selectedWish) ? (
						<Popup
							content={
								<>
									<WishItem wish={selectedWish} strip_name={true}/>
									<br/>
									<WishStatus wish={selectedWish}/>
								</>
							}
							onClose={() => setSelectedWish(null)}
						/>
					) : null
				}
			</div>
		</>
	);
}

export default WishList;
